<template>
  <div class="staff-container">
    <prescriptionFilter ref="prescriptionFilter">
      <el-button type="primary" size="small" @click="handleQuery()"
        >查询</el-button
      >
      <el-button type="success" size="small" @click="handleCreate"
        >添加</el-button
      >
    </prescriptionFilter>
    <div class="tabs-container">
      <el-tabs v-model="checkType" @tab-click="typeChange">
        <el-tab-pane
          v-for="tab in $config.checkType"
          :key="tab.key"
          :name="tab.key"
          :label="tab.name"
        >
        </el-tab-pane>
      </el-tabs>
    </div>
    <div class="tab-content">
      <prescriptionList ref="prescriptionList" :filter="filter" />
    </div>
  </div>
</template>
<script>
import prescriptionFilter from "./components/prescriptionFilter";
import prescriptionList from "./components/prescriptionList";
export default {
  components: {
    prescriptionFilter,
    prescriptionList,
  },
  data() {
    return {
      filter: "",
      checkType: "KneeHurt",
    };
  },
  methods: {
    handleQuery() {
      let tempfilter = this.$refs.prescriptionFilter.getfilter();
      tempfilter.checkType = this.checkType;
      this.filter = tempfilter;
    },
    handleCreate() {
      this.$refs.prescriptionList.handleCreate();
    },
    typeChange(v) {
      this.handleQuery();
    },
  },
  mounted() {
    this.handleQuery();
  },
};
</script>
<style lang='scss' scoped>
.staff-container {
  .input {
    width: 150px;
  }

  .tabs-container {
    /deep/.el-tabs--border-card > .el-tabs__content {
      padding: 0;
      height: 1px;
    }

    .tab-content {
      margin-top: -2px;
      border: 1px solid #dcdfe6;
      border-top: 0;
      padding: 15px;
      background: #fff;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.12), 0 0 6px 0 rgba(0, 0, 0, 0.04);
    }
  }
}
</style>